import React, { useEffect ,useMemo} from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
import ServiceCategory from "../components/services/widgets/ServiceCategory";
import BlogDetailsContent from "../components/blog/BlogDetailsContent";
import SectionsTitle from "../components/common/SectionsTitle";
import { useLocation } from "react-router-dom";
// import { useQuary } from "react-router-dom";
import Chart from "chart.js/auto"
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import GetInTouchButton from "../components/about/GetInTouchButton";

import { CustomSegmentLabelPosition } from 'react-d3-speedometer'
function InvestmentApproaches() {
  const isMobile = useMemo(() => {
    return window.innerWidth <= 768; 
  }, []);
  const lists = [
    {
      id: "0",
      title: " Momentum",
      blog: {
        title: " Agreya Momentum",
        author: "Fund Manager: Arjun Narsipur",
        benchmark: "Benchmark: NIFTY 50 TRI",
        content:
          "The objective of the Investment Approach (IA) is to generate capital appreciation through investments in equities with a long term outlook. The IA aims to generate better risk-adjusted returns compared to the benchmark by investing in large cap equities with strong momentum with a technical overlay determining buy-sell decisions for investments and exits.",
      },
    },
    {
      id: "1",
      title: "Total Return ",
      blog: {
        title: "Agreya Total Return ",
        author: "Fund Manager: Shailendra Agarwal",
        benchmark:"Benchmark: S&P BSE 500 TRI",
        content:
          "The objective of the Investment Approach (IA) is to generate high capital appreciation through a curated basket of multi cap equity investments with patient long-term orientation. The IA seeks to deliver absolute long-term outperformance over the benchmark.",
        
      },
    },
    {
      id: "2",
      title: "Multi-Asset ",
      blog: {
        title: "Agreya Multi-Asset",
        author:
          "Fund Manager: Arjun Narsipur & Shailendra Agarwal",
          benchmark:"Benchmark: NSE Multi Asset Index 1",
          content:
          "The objective of the Investment Approach (IA) is to generate moderate returns through investments in select equity, debt and quasi debt instruments curated through Agreya’s proprietary process. The underlying investments in the IA is across permissible securities and customized to suit each investor's specific needs.",
      },
    },
    {
      id: "3",
      title: "Momentum Enhancer ",
      blog: {
        title: "Agreya Momentum Enhancer",
        author:
          "Fund Manager: Arjun Narsipur",
          benchmark:"Benchmark:NIFTY 50 TRI",
          content:
        "The objective of the Investment Approach (IA) is to generate enhanced risk- adjusted returns by investing the majority (~90%) of the capital in Agreya Momentum and the rest (~10%) is invested in derivatives to capture short term trends in equity indices. The IA avoids blow out risks by only buying options."
      },
    },
    {
      id: "4",
      title: "Total Return Enhancer",
      blog: {
        title: " Agreya Total Return Enhancer",
        author:
          "Fund Manager:Shailendra Agarwal & Arjun Narsipur",
          benchmark:"Benchmark: S&P BSE 500 TRI",
          content:
          "The objective of the Investment Approach (IA) is to generate enhanced absolute returns by investing the majority (~90%) of the capital in Agreya Total Returns and the rest (~10%) is invested in derivatives to capture short term trends in equity indices. The IA avoids blow out risks by only buying options.",
      },
    },
    {
      id: "5",
      title: "Multi-Asset Enhancer ",
      blog: {
        title: "Agreya Multi-Asset Enhancer ",
        author:
          "Fund Manager: Arjun Narsipur & Shailendra Agarwal",
          benchmark:"Benchmark:NSE Multi Asset Index 1",
          content:
          "The objective of the Investment Approach (IA) is to provide enhanced returns by investing the majority (90%) of the capital in Agreya Multi-Asset and the rest (~10%) is invested in derivatives to capture short term trends in equity indices. The IA avoids blow out risks by only buying options.",
      },
    },
    {
      id: "6",
      title: "Portfolio Return Optimiser",
      blog: {
        title: " Agreya Portfolio Return Optimiser",
        author:
          "Fund Manager: Arjun Narsipur",
          benchmark:"Benchmark: NSE Multi Asset Index 1",
          content:
          "The objective of the Investment Approach (IA) is to enhance the returns of underlying equity and debt holdings already held by the investor and use a small portion (~10%) of the capital in derivatives to capture short term trends in equity indices. The IA avoids blow out risks by only buying options.",
      },
    },
  ];
  const approachChartData = {
    "Momentum": {
      labels: ['1M', '3M', '6M', '1Y', '2Y', '3Y', '4Y', '5Y', 'SI'],
      datasets: [
        
        {
          label: 'Agreya Momentum',
          backgroundColor: '#033132',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          categoryPercentage: 4,
          data: [	-7.46,-10.11,-1.82,33.45,24.23,14.43,22.14,20.83,15.30],
        },  
        {
          label: 'NIFTY 50 TRI',
          backgroundColor: '#C39239',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-6.12,-2.67,8.10,28.39,17.20,12.38,21.50,16.62,13.87],

        },
      ],
    },
    "Total Return": {
      labels: ['1M', '3M', '6M', '1Y', '2Y', '3Y', '4Y', '5Y','SI'],
      datasets: [
        {
          label: 'Agreya Total Return',
          backgroundColor: '#033132',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [0.06,6.92,16.52,39.31,25.98,15.08,30.27,27.17,25.22],
        },
        {
          label: 'S&P BSE-500 TRI',
          backgroundColor: '#C39239',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-6.45,-3.58,8.71,35.90,22.05,15.68,25.35,19.84,16.24],
        },
      ],
    },
    "Multi-Asset Enhancer": {
      labels: ['1M', '3M', '6M', '1Y', '2Y', '3Y', '4Y','SI' ],
      datasets: [
        {
          label: 'Agreya Multi-Asset Enhancer',
          backgroundColor: '#033132',
          borderWidth: 1,
          barThickness: isMobile? 10: 30,
          data: [-0.51,1.98,5.47,15.44,9.68,9.66,17.42,14.00,14.57],
        }, 
        {
          label: 'NSE Multi Asset Index 1',
          backgroundColor: '#C39239',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-2.64,-0.41,7.01,23.20,15.08,11.63,16.38,13.48,12.59],
        },
      ],
    },
    "Total Return Enhancer": {
      labels: ['1M', '3M', '6M', '1Y', '2Y','3Y','SI'],
      datasets: [
        {
          label: 'Agreya Total Return Enhancer',
          backgroundColor: '#033132',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-1.16,3.92,10.76,36.66,25.72,16.24,21.74],
        },
        {
          label: 'S&P BSE 500 TRI',
          backgroundColor: '#C39239',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-6.45,-3.58,8.71,35.90,22.05,15.68,18.91],
        },
      ],
    },
    "Portfolio Return Optimiser": {
      labels: ['1M', '3M', '6M', '1Y','2Y','SI'],
      datasets: [
        {
          label: 'Agreya Portfolio Return Optimiser',
          backgroundColor: '#033132',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-6.53,-3.83,1.50,9.27,11.87,12.56],
        },
        {
          label: 'NSE Multi Asset Index 1',
          backgroundColor: '#C39239',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-2.64,-0.41,7.01,23.20,15.08,13.92],
        },
      ],
    },
    "Multi-Asset": {
      labels:  ['1M', '3M', '6M', '1Y', '2Y', '3Y','4Y','5Y','SI'],
      datasets: [
        {
          label: 'Agreya Multi-Asset',
          backgroundColor: '#033132',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-0.95,1.78,7.27,16.94,11.80,9.13,12.49,11.12,10.77],
        },  						
        {
          label: 'NSE Multi Asset Index 1',
          backgroundColor: '#C39239',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-2.64,-0.41,7.01,23.20,15.08,11.63,16.38,13.48,13.23],
        },
      ],
    },
    "Momentum Enhancer": {
      labels: ['1M', '3M', '6M','1Y','SI'],
      datasets: [
        {
          label: 'Agreya Momentum Enhancer',
          backgroundColor: '#033132',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [	-6.45,-8.31,-1.74,36.70,37.52],
        },
        {
          label: 'Nifty 50 TRI',
          backgroundColor: '#C39239',
          borderWidth: 1,
          barThickness: isMobile? 15: 30,
          data: [-6.12,-2.67,8.10,28.39,23.72],
        },
      ],
    },
  };
  
  const [blog, setBlog] = React.useState(lists[0].blog);
  const [barChartData, setBarChartData] = React.useState(approachChartData[lists[0].title] || {});
  // const [speedometerValue, setSpeedometerValue] = React.useState(0);
  const [speedometerValue, setSpeedometerValue] = React.useState(0);
  
  
  const updateBlog = (blog) => {
    setBlog(blog);

  };
  const { search } = useLocation();
  const useQuery = () => {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();
  useEffect(() => {
    const number = parseInt(query.get("name")) || 0;
    if (!isNaN(number) && lists[number]) {
      const selectedBlog = lists[number].blog;
      setBlog(selectedBlog);
      const selectedApproachTitle = lists[number].title.trim();
      const selectedChartData = approachChartData[selectedApproachTitle];
      if (selectedChartData && Array.isArray(selectedChartData.labels) && Array.isArray(selectedChartData.datasets)) {
        setBarChartData(selectedChartData);
      } else {
        setBarChartData({});
      }

      const riskLevelValues = {
        "Very High Risk": 900,
        "Moderately High risk": 500,
        "High risk": 700,
      };

      const riskLevelNames = {
        " Agreya Momentum": "High risk",
        "Agreya Total Return ": "Very High Risk",
        "Agreya Multi-Asset Enhancer ": "High risk",
        " Agreya Total Return Enhancer": "Very High Risk",
        " Agreya Portfolio Return Optimiser": "Very High Risk",
        "Agreya Multi-Asset": "Moderately High risk",
        "Agreya Momentum Enhancer": "Very High Risk",
      };
      setSpeedometerValue(riskLevelValues[riskLevelNames[selectedBlog.title]] || 0);
    }
  }, [query]);
  const barChartOptions = {
 
 
  animation: {
    duration: 4000,
  },
  layout: {
    padding: {
      padding: 20
  }
},
 scales: {   
      x: {
        grid: {
          display: false,
        },
        offset: true,
        barPercentage: 4
        
      },   
      y: {
        grid: {
          display: false,
        },
        offset: true,
        barPercentage: 6,
        ticks: {
          callback: (value, index, values) => {
            return `${value}%`;
          },
          stepSize: 10,
        },
      },
      
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        
        anchor: 'end',
        align: 'end',
        display: true,
        color: 'black',
        font: {
          size: isMobile ? 5 : 10, 
        },
        formatter: (value, context) => {

          return `${value}%`;
        },
        maxWidth: 1,
      },
    },
    // suggestedMin: 0, // Set the minimum value on the y-axis
    // suggestedMax: 40,
    maintainAspectRatio: false,
  };
  const customSegmentLabels = [
    {
      text: "Low",
      position: CustomSegmentLabelPosition.Inside ,
      color: "#555",
      fontSize: "8px",
      
    },

    {
      text: "Moderate",
      position: CustomSegmentLabelPosition.Inside ,
      color: "#555",
      fontSize: "8px",
    },
    {
      text: "Moderately High",
      position:CustomSegmentLabelPosition.Inside ,
      color: "#555",
      fontSize: "8px",
    },
    {
      text: "High",
      position: CustomSegmentLabelPosition.Inside ,
      color: "#555",
      fontSize: "8px",
    },
    {
      text: "Very High",
      position: CustomSegmentLabelPosition.Inside ,
      color: "#555",
      fontSize: "8px",
    },
   
   
  ];
  const segmentColors = ["#44ce1b", "#bbdb44", "#f7e379","#ffa550", "	#ff5252", "#FF0000"];


const DisclaimerText = 'The above returns are not verified by SEBI';
// const PerformanceText = `Performance as of Mar'24`;
  let date="Oct 31, 2024";

  const sinceInceptionTexts = {
    "Agreya Momentum": `As of ${date}  \u00A0  \u00A0 Since Inception (SI) Date: 31/08/2018`,
    "Agreya Total Return": `As of ${date}  \u00A0  \u00A0 Since Inception (SI) Date: 14/09/2018`,
    "Agreya Multi-Asset Enhancer": `As of ${date}  \u00A0  \u00A0 Since Inception (SI) Date: 04/06/2019`,
    "Agreya Total Return Enhancer": `As of ${date}  \u00A0  \u00A0 Since Inception (SI) Date: 22/02/2021`,
    "Agreya Portfolio Return Optimiser": `As of ${date}  \u00A0  \u00A0 Since Inception (SI) Date: 16/03/2022`,
    "Agreya Multi-Asset": `As of ${date}  \u00A0 \u00A0 Since Inception (SI) Date: 02/03/2019`,
    "Agreya Momentum Enhancer": `As of ${date}  \u00A0   \u00A0Since Inception (SI) Date: 11/03/2023`,
  };
  
  // const isMobile = window.innerWidth <= 768;
  // {console.log("cxcxsMobile:", isMobile)}
  return (
    <main>
      <header className="header-area">
        <Navbar />
        <ToastContainer />
        <div className="get-in-touch-container">
          <GetInTouchButton />
        </div>
      </header>
      <div class="con" style={{ marginTop: "100px" }}>
        <div class="inv" style={{ display: "flex", maxWidth: "100%", paddingTop: "-10px" }}>
          <div style={{ maxWidth: "250px", margin: "30px 20px 0 50px" }}>
            <ServiceCategory lists={lists} updateBlog={updateBlog} />
          </div>
          <div style={{ width: "100%", marginTop: "-50px", marginLeft: "2px", marginBottom: "20px" }}>
            <BlogDetailsContent blog={blog} speedometerValue={speedometerValue} customSegmentLabels={customSegmentLabels} segmentColors={segmentColors} isMobile={isMobile}/>
            {/* <div style={{ overflowX: "auto", width: "100%",height: "100%" }}> */}
              <div class="bar" style={{ overflowX: "auto", width: isMobile ? "100%" : "68%", height:isMobile?"500px": "320px", justifyContent: "center", display: "flex", marginTop: "-70px", marginLeft: isMobile ? "0" : "15%" }}>
                {barChartData && barChartData.datasets ? (
                  <>
                    <Bar data={barChartData} options={barChartOptions} plugins={[ChartDataLabels]} />
                  </>
                ) : (
                  <p>Loading chart data...</p>
                )}
              </div>
            {/* </div> */}
            <div style={{ textAlign:"center", marginTop: "5px", fontSize: "12px", }}>
              <p>{sinceInceptionTexts[blog.title.trim()]}</p>
            </div>
            <div style={{textAlign:"center", marginTop: "-5px", fontSize: "9px"}}>
              <p>{DisclaimerText}</p>
            </div>
            {/* <div style={{ textAlign:isMobile?"right":"left",marginTop: isMobile ? "-500px" : "-300px", fontSize: isMobile ?"10px":"12px" }}>
              <p>{PerformanceText}</p>
            </div> */}
          </div>
        </div>
      </div>
    </main>
  );
  
  }
  export default InvestmentApproaches;